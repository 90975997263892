.geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
}
.geosuggest__item {
    list-style-type: none;
    font-size: 14px;
    line-height: 1.4rem;
    padding: 10px;
    margin-left: 0;
}

.geosuggest__suggests {
    padding: 0;
    box-sizing: border-box;
    box-shadow: 0.4rem 0.4rem 0.4rem #e5e5e5;
    background: transparent;
    border-top: 0.1rem solid #e5e5e5;
    border-bottom: 0.1rem solid #e5e5e5;
    border-left: 0.1rem solid #e5e5e5;
    border-right: 0.1rem solid #e5e5e5;
    border-radius: 0;
    outline: 0;
    background-color: #fafafa;
}